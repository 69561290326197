:global {

	.bootstrap-container {
		margin-left: auto;
		margin-right: auto;
		width: 92%;
		max-width: 1440px;
	  }

}
.bootstrap-container {
  margin-left: auto;
  margin-right: auto;
  width: 92%;
  max-width: 1440px;
}

.bt-container {
	max-width: 1440px;
}